import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  closeIcon: {
    width: '15px',
    height: '15px',
    color: '#000000 !important'
  }
}));

export default useStyles;
