import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '0 32px'
  },
  contentContainer: {
    height: 'calc(100vh - 88px)',
    overflow: 'hidden'
  }
}));

export default useStyles;
