import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, createFilterOptions } from '@mui/material';
import { DROPDOWN_INPUT_WIDTH } from 'src/utils/constants/numericConstants';
import Option from './Option';
import { updateLimitTags } from './utils';
import { sxProps } from './sxProps';
import CustomTextField from '../CustomTextField/CustomTextField';
import Tag from './Tag';

function OptionDropdown({
  options,
  selectedOptions,
  updateOptions,
  styles,
  loading,
  label,
  size,
  disabled,
  classes,
  placeholder,
  inputLabelProps,
  limitTags
}) {
  const [dynamicLimitTags, setDynamicLimitTags] = useState(1);
  const tagRefs = useRef([]);
  const inputRef = useRef(null);

  const handleLimitTags = (visibleTags) => {
    setDynamicLimitTags(visibleTags);
  };

  useEffect(() => {
    if (limitTags) {
      updateLimitTags(handleLimitTags, inputRef, tagRefs);
      window.addEventListener('resize', updateLimitTags(handleLimitTags, inputRef, tagRefs));
    }

    return () => {
      if (limitTags) {
        window.removeEventListener('resize', updateLimitTags(handleLimitTags, inputRef, tagRefs));
      }
    };
  }, [selectedOptions]);

  const renderInput = (params) => (
    <CustomTextField
      {...params}
      ref={inputRef}
      label={label}
      variant="outlined"
      InputLabelProps={inputLabelProps}
      placeholder={placeholder}
    />
  );

  const handleChangeOptions = (event, changedOptions) => {
    updateOptions(changedOptions);
  };

  const removeOption = (id) => {
    updateOptions(selectedOptions.filter((option) => option.id !== id));
  };

  const setTagRef = (index, el) => {
    if (el) {
      tagRefs.current[index] = el;
    } else {
      tagRefs.current = tagRefs.current.filter((_, i) => i !== index);
    }
  };

  const renderTags = (values, getTagProps) => {
    const inputWidth = inputRef.current ? inputRef.current.offsetWidth : DROPDOWN_INPUT_WIDTH;

    const getTags = () =>
      values.map((value, index) => (
        <Tag
          value={value}
          index={index}
          getTagProps={getTagProps}
          removeOption={removeOption}
          setTagRef={setTagRef}
          tagRefs={tagRefs}
          inputWidth={inputWidth}
          limitTags={limitTags}
          disabled={disabled}
          classes={classes}
        />
      ));
    return getTags();
  };

  const filterOptions = createFilterOptions({
    stringify: (option) => option.name
  });

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      limitTags={limitTags ? dynamicLimitTags : -1}
      style={styles}
      size={size}
      value={selectedOptions}
      filterOptions={filterOptions}
      options={options || []}
      disableClearable={disabled}
      getOptionDisabled={() => loading}
      onChange={handleChangeOptions}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => (
        <Option option={option} selected={selected} loading={loading} props={props} />
      )}
      renderInput={renderInput}
      renderTags={renderTags}
      sx={sxProps(limitTags)}
    />
  );
}

OptionDropdown.propTypes = {
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  updateOptions: PropTypes.func,
  styles: PropTypes.object,
  loading: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  chipStyle: PropTypes.object,
  placeholder: PropTypes.string,
  inputLabelProps: PropTypes.object,
  limitTags: PropTypes.number
};

OptionDropdown.defaultProps = {
  loading: false
};

export default OptionDropdown;
