import { Button, CardContent, TextField, Tooltip, Typography, Autocomplete } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDeleteModal from 'src/components/ConfirmDeleteModal';
import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { funcValidation } from 'src/utils/validation';
import useStyles from '../styles';
import { availableTypes, CUSTOM_FIELD_NAME_ERROR } from '../../utils';

const AddEditCustomField = ({ customFields, onListChange }) => {
  const [errors, setErrors] = useState([]);
  const [customFieldList, setCustomFieldList] = useState(() => {
    const cachedList = cloneDeep(customFields);
    return (
      cachedList?.map((item) => ({
        ...item,
        key: uuid()
      })) || []
    );
  });

  const checkCustomFieldName = (customField) =>
    !!customField.name?.trim() &&
    !customFieldList.some(
      (customFieldName) =>
        customFieldName.name?.trim() === customField.name?.trim() &&
        customFieldName.key !== customField.key
    );

  const validationFieldSettings = useCallback(
    () => ({
      name: {
        customValid: ['name', (customField) => checkCustomFieldName(customField)]
      },
      type: {
        required: 'type'
      }
    }),
    [customFieldList]
  );

  useEffect(() => {
    const errors = {};
    const validationFields = validationFieldSettings();
    customFieldList.forEach((customField) => {
      errors[customField.key] = {
        name: funcValidation(validationFields.name, customField, CUSTOM_FIELD_NAME_ERROR),
        type: funcValidation(validationFields.type, customField.type)
      };
    });
    setErrors(errors);
    onListChange(customFieldList, errors);
  }, [customFieldList, validationFieldSettings]);

  const classes = useStyles();
  const [openModalDeleteAnswer, setOpenModalDeleteAnswer] = useState(false);
  const [customFieldDeleted, setCustomFieldDeleted] = useState({});

  const handleFieldChange = (event, fieldKey, fieldName) => {
    const fieldType = event || { type: '' };
    const newValue = event?.target ? event.target.value : fieldType;
    const newField = fieldName === 'type' ? newValue : { [fieldName]: newValue };
    setCustomFieldList((prevList) =>
      prevList.map((field) => (field.key === fieldKey ? { ...field, ...newField } : field))
    );
  };

  const removeListItem = () => {
    const keyToDelete = customFieldDeleted.key;
    const newErrors = { ...errors };
    delete newErrors[keyToDelete];
    setCustomFieldList((list) => list.filter((item) => item.key !== customFieldDeleted.key));
    setErrors(newErrors);
    setOpenModalDeleteAnswer(false);
  };

  const removeListItemWithoutConfirmation = (customField) => {
    setCustomFieldList((list) => list.filter((item) => item.key !== customField.key));
  };

  const onDeleteCustomField = (customField) => {
    setCustomFieldDeleted(customField);
    setOpenModalDeleteAnswer(true);
  };

  const addListItem = () => {
    const newItem = {
      name: '',
      type: '',
      key: uuid()
    };
    setCustomFieldList((list) => [...list, newItem]);
  };

  return (
    <div>
      <ConfirmDeleteModal
        open={openModalDeleteAnswer}
        onClose={() => setOpenModalDeleteAnswer(false)}
        submit={removeListItem}
        title="Delete Custom Field"
        text="Are you sure you want to delete custom field? It cannot be undone."
      />
      <CardContent className={clsx(classes.cardContent)}>
        {customFieldList?.map((currentField, index) => (
          <div key={currentField.key}>
            <Typography className={clsx(classes.customFieldHeader)} variant="body1">
              {`Custom Field ${index + 1}`}
              <DeleteIcon
                className={clsx(classes.deleteItemIcon, classes.icon)}
                onClick={() =>
                  !currentField.name && !currentField.type
                    ? removeListItemWithoutConfirmation(currentField)
                    : onDeleteCustomField(currentField)
                }
              />
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className={clsx(classes.customFieldsWrapper)}
            >
              <TextField
                data-testid="fieldNameInput"
                className={clsx(classes.customField)}
                fullWidth
                required
                error={Boolean(errors[currentField.key]?.name)}
                helperText={errors[currentField.key]?.name}
                label="Field Name"
                margin="normal"
                name="fieldName"
                onChange={(event) => handleFieldChange(event, currentField.key, 'name')}
                value={currentField.name || ''}
                variant="outlined"
                inputProps={{
                  maxLength: 100
                }}
              />
              <Autocomplete
                className={clsx(classes.customField)}
                autoHighlight
                data-testId="fieldTypeAutocomplete"
                size="medium"
                fullWidth
                options={availableTypes}
                value={availableTypes.find((option) => option.type === currentField.type) || null}
                getOptionLabel={(option) => option.type}
                onChange={(event, option) => handleFieldChange(option, currentField.key, 'type')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    error={Boolean(errors[currentField.key]?.type)}
                    helperText={errors[currentField.key]?.type}
                    margin="normal"
                    name="type"
                    label="Field Type"
                    variant="outlined"
                  />
                )}
              />
            </Typography>
          </div>
        ))}
      </CardContent>
      <Tooltip
        disableInteractive
        title={customFieldList?.length >= 10 ? 'You can create up to 10 custom fields.' : ''}
      >
        <div className={clsx(classes.buttonContainer)}>
          <Button disabled={customFieldList?.length >= 10} color="primary" onClick={addListItem}>
            ADD CUSTOM FIELD
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};

AddEditCustomField.propTypes = {
  customFields: PropTypes.array,
  onListChange: PropTypes.func
};

export default AddEditCustomField;
