import React from 'react';
import Tabs from 'src/components/Tabs';
import { useSelector } from 'react-redux';
import useStyles from './styles';
import {
  ADMIN_ROLE,
  EDITOR_ROLE,
  TRIAL_USER_ROLE,
  USER_ROLE,
  VIEWER_USER_ROLE
} from '../../../../utils/constants/stringConstants';

function TabsHeader() {
  const classes = useStyles();
  const userRole = useSelector((state) => state.session.user.role);

  const viewerUserTabs = [{ name: 'Deals', link: '/deal/page/deals' }];

  const trialUserTabs = [
    ...viewerUserTabs,
    { name: 'Portfolios', link: '/deal/page/portfolios' },
    { name: 'Topics', link: '/deal/page/topics' },
    { name: 'Comparison', link: '/deal/page/comparison' }
  ];

  const commonTabs = [
    ...trialUserTabs,
    { name: 'Track changes', link: '/deal/page/track-changes' }
  ];

  const getUserTab = () => {
    switch (userRole) {
      case ADMIN_ROLE:
      case EDITOR_ROLE:
      case USER_ROLE:
        return commonTabs;
      case TRIAL_USER_ROLE:
        return trialUserTabs;
      case VIEWER_USER_ROLE:
        return viewerUserTabs;

      default:
        return commonTabs;
    }
  };

  return (
    <Tabs
      containerStyle={classes.container}
      wrapperTab={classes.wrapper}
      tabs={getUserTab()}
      normalStyle={classes.tab}
      wrapperActiveStyle={classes.activeWrapper}
      wrapperNormalStyle={classes.normalWrapper}
      activeStyle={classes.active}
    />
  );
}

export default TabsHeader;
