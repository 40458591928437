import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Button, Grid, Modal, TextField, Typography } from '@mui/material';
import { AttachFile } from '@mui/icons-material';
import PropTypes from 'prop-types';
import modalStyles from 'src/assets/scss/modalWindow';
import clsx from 'clsx';
import { areInputValuesNotEqual, funcValidation } from 'src/utils/validation';
import useStyles from './styles';
import { removeExtraSpaceFromObjectValues } from '../../../../../utils/common';
import FileName from '../../../../../components/FileName';

const initialState = {
  values: {
    imageKey: null,
    imageBase64: '',
    fileName: '',
    firstName: '',
    lastName: '',
    position: ''
  },
  touched: {},
  errors: {}
};

const AddEditEmployeeModal = ({ open, onClose, data: employeeData, submit }) => {
  const classes = useStyles();
  const modalClasses = modalStyles();
  const fileInputRef = useRef();
  const [formState, setFormState] = useState(initialState);

  const employeeContainsImage = useMemo(
    () => !!formState.values.imageKey || !!formState.values.fileName,
    [formState.values.imageKey, formState.values.fileName]
  );

  const validationFieldSettings = useCallback(
    () => ({
      firstName: {
        required: 'firstName'
      },
      lastName: {
        required: 'lastName'
      },
      position: {
        required: 'position'
      }
    }),
    []
  );

  const handleChange = ({ target: { name, value } }) => {
    const hasChanges = areInputValuesNotEqual(employeeData && employeeData[name], value);
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [name]: value
      },
      touched: {
        ...prevFormState.touched,
        [name]: hasChanges
      }
    }));
  };

  const handleClose = () => {
    onClose(Object.values(formState.touched).some((value) => value));
  };

  const save = () => {
    const params = removeExtraSpaceFromObjectValues({ ...formState.values });
    submit(params, employeeData?.id);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormState((prevFormState) => ({
          ...prevFormState,
          values: {
            ...prevFormState.values,
            imageBase64: reader.result,
            fileName: file.name,
            imageKey: null
          },
          touched: {
            ...formState.touched,
            imageBase64: true
          }
        }));
      };
      reader.readAsDataURL(file);
      fileInputRef.current.value = '';
    }
  };

  const handleDeleteFile = () => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        imageBase64: null,
        fileName: null,
        imageKey: null
      },
      touched: {
        ...prevFormState.touched,
        imageBase64: !!employeeData?.imageBase64
      }
    }));
  };

  useEffect(() => {
    if (!!employeeData && open) {
      setFormState({
        ...formState,
        values: {
          ...formState.values,
          imageKey: employeeData?.imageKey,
          imageBase64: employeeData?.imageBase64,
          fileName: employeeData?.fileName,
          firstName: employeeData?.firstName,
          lastName: employeeData?.lastName,
          position: employeeData?.position
        },
        touched: {},
        errors: {}
      });
    } else {
      setFormState(initialState);
    }
  }, [open, employeeData]);

  useEffect(() => {
    if (open) {
      const errors = {};
      const validationFields = validationFieldSettings();
      Object.keys(validationFields).forEach((fieldName) => {
        const formStateValues = formState.values[fieldName] ? `${formState.values[fieldName]}` : '';
        errors[fieldName] = funcValidation(validationFields[fieldName], formStateValues);
      });

      setFormState((prevFormState) => ({
        ...prevFormState,
        errors
      }));
    }
  }, [open, formState.values, validationFieldSettings]);

  const isSaveDisabled =
    Object.values(formState.errors).some((value) => !!value) ||
    !Object.values(formState.touched).some((value) => value);

  const hasError = (fieldName) => !!formState.errors[fieldName] && !!formState.touched[fieldName];

  const getErrorText = (fieldName) => (hasError(fieldName) ? formState.errors[fieldName] : '');

  return (
    <Modal onClose={handleClose} open={open}>
      <Grid className={clsx(modalClasses.root, classes.modalForm)}>
        <Grid className={classes.header}>
          <Typography variant="body" className={classes.headerTitle}>
            {!employeeData ? 'Add Employee' : 'Edit Employee'}
          </Typography>
        </Grid>
        <Grid className={classes.content}>
          <Grid className={classes.fieldColumns}>
            <Grid className={classes.column}>
              <TextField
                required
                error={hasError('firstName')}
                helperText={getErrorText('firstName')}
                name="firstName"
                label="First name"
                value={formState.values.firstName}
                InputLabelProps={{ shrink: true }}
                className={classes.inputField}
                onChange={handleChange}
                inputProps={{
                  maxLength: 70
                }}
              />
              <TextField
                required
                error={hasError('position')}
                helperText={getErrorText('position')}
                name="position"
                label="Position"
                value={formState.values.position}
                InputLabelProps={{ shrink: true }}
                className={classes.inputField}
                onChange={handleChange}
                inputProps={{
                  maxLength: 50
                }}
              />
            </Grid>
            <Grid className={classes.column}>
              <TextField
                required
                error={hasError('lastName')}
                helperText={getErrorText('lastName')}
                name="lastName"
                label="Last name"
                value={formState.values.lastName}
                InputLabelProps={{ shrink: true }}
                className={classes.inputField}
                onChange={handleChange}
                inputProps={{
                  maxLength: 70
                }}
              />
            </Grid>
          </Grid>
          <Grid className={classes.actions}>
            <Grid className={classes.leftActions}>
              <Button
                variant="contained"
                className={classes.attachButton}
                onClick={() => fileInputRef.current.click()}
              >
                <AttachFile className={classes.attachFileIcon} />
                {employeeContainsImage ? 'Change file' : 'Attach file'}
              </Button>
              <input
                accept="image/png, image/jpeg, image/jpg"
                style={{ display: 'none' }}
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              {employeeContainsImage && (
                <FileName
                  imageKey={formState.values.imageKey}
                  fileName={formState.values.fileName}
                  handleDeleteFile={handleDeleteFile}
                />
              )}
            </Grid>
            <Grid className={classes.rightActions}>
              <Button onClick={() => handleClose()} className={classes.cancelButton}>
                Cancel
              </Button>
              <Button
                disabled={isSaveDisabled}
                variant="contained"
                className={classes.saveButton}
                onClick={() => save()}
              >
                {!employeeData ? 'Add Employee' : `Save changes`}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

AddEditEmployeeModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  data: PropTypes.object,
  submit: PropTypes.func
};

AddEditEmployeeModal.defaultProps = {
  open: false
};

export default AddEditEmployeeModal;
