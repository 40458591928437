import axios from 'axios';
import UserPool from 'src/UserPool';

const permitAll = ['/email/contact-us', '/email/subscribe', '/home', '/auth/signUp', '/news/post'];

export const initDefaultAxiosConfig = (history) => {
  const checkPermissions = () => {
    if (permitAll.some((url) => url === history.location.pathname)) {
      return;
    }
    window.location.replace('/auth/login');
  };

  axios.interceptors.request.use(
    async (config) => {
      if (permitAll.some((url) => url === config.url)) {
        return config;
      }

      const getSession = new Promise((resolve, reject) => {
        const loggedUser = UserPool.getCurrentUser();
        if (loggedUser) {
          loggedUser.getSession((error, activeSession) => {
            if (error) {
              loggedUser.signOut();
              window.location.replace('/auth/login');
              reject(error);
            } else {
              loggedUser.getUserData((err, res) => {
                if (res) {
                  const checkUserEmailVerification = res.UserAttributes.some(
                    (attr) => attr.Name === 'email_verified' && attr.Value === 'true'
                  );

                  if (checkUserEmailVerification) {
                    resolve(activeSession);
                  } else {
                    checkPermissions();
                  }
                }
              });
            }
          });
        } else {
          checkPermissions();
          reject();
        }
      });

      const userSession = await getSession;
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        Authorization: userSession.getIdToken().getJwtToken(),
        Access: userSession.getAccessToken().getJwtToken()
      };
      return config;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401 || error?.message === 'The incoming token has expired') {
        const loggedUser = UserPool.getCurrentUser();
        loggedUser?.signOut();
        window.location.replace('/auth/login');
        return Promise.reject(error);
        // eslint-disable-next-line no-else-return
      } else if (error?.response?.status === 403) {
        window.location.replace('/errors/error-403');
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
};

const instance = axios.create({
  baseURL: 'https://jsonplaceholder.typicode.com',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8'
  }
});

export const client = axios.create({
  baseURL: process.env.DOMAIN
});

export default instance;
