import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  content: {
    backgroundColor: '#f4f6f8',
    padding: '68px 30px 0px 30px'
  },
  alertInfo: {
    top: '100px'
  },
  home: {
    padding: '64px 0px 0px 0px',
    overflowX: 'hidden'
  },
  aboutUs: {
    padding: '64px 0px 0px 0px'
  },
  errors: {
    padding: '0px'
  }
}));

export default useStyles;
