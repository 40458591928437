import moment from 'moment';
import _ from 'lodash';
import { DATE_TYPE } from 'src/MainLayout/PDFLayout/PDFTabs/DealTab/DealDetailsTab/constants';
import { areInputValuesNotEqual } from './validation';

export const cutStr = (str, len) => {
  if (!str) {
    return;
  }
  const cutedStr = str.substr(0, len);
  // eslint-disable-next-line consistent-return
  return str.length > len ? `${cutedStr} ...` : str;
};

export const sortOrdersArray = (numArray, fieldName) =>
  numArray.sort((a, b) => a[fieldName] - b[fieldName]);

export const getDateValue = (value) => (value ? moment(value).format('YYYY-MMM-DD') : '');
export const getDateTimeValue = (value) => (value ? moment(value).format('YYYY-MMM-DD HH:mm') : '');
export const isToday = (dateString) => {
  const inputDate = new Date(dateString);
  const today = new Date();

  return (
    inputDate.getFullYear() === today.getFullYear() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getDate() === today.getDate()
  );
};
export const getCellComponentCustomFileds = (cell, id, type) => {
  if (cell.row.customFields) {
    if (type === DATE_TYPE) {
      return getDateValue(cell.row.customFields[id]);
    }
    return cell.row.customFields[id];
  }
  return '';
};
export const trimValue = (value) => value && value.trim();

export const removeExtraSpaceFromObjectValues = (obj) => {
  const cachedObject = { ...obj };
  Object.keys(cachedObject).forEach((key) => {
    cachedObject[key] =
      typeof cachedObject[key] === 'string' &&
      (cachedObject[key] !== null || cachedObject[key] !== undefined)
        ? trimValue(obj[key])
        : cachedObject[key];
  });
  return cachedObject;
};

export const isNumberValuePresent = (value) => value !== null && value !== undefined;

export const isShrink = (value) =>
  (Boolean(value) ? true : undefined) || (value === 0 ? true : undefined);

export const isArrayEqual = (x, y) => _(x).differenceWith(y, _.isEqual).isEmpty();

export const areArraysEqual = (x, y) => isArrayEqual(x, y) && isArrayEqual(y, x);

export const isArrayNotEqual = (x, y) => !isArrayEqual(x, y);

export const areStringsEqualIgnoreCase = (x, y) => x.toLowerCase() === y.toLowerCase();

export const arrayContainsAllElements = (arr1, arr2) =>
  arr2.every((element) => arr1.includes(element));

export const areValuesNotEqual = (prevValue, value) => {
  const normalizedPrevValue = prevValue ?? '';
  const normalizedValue = value ?? '';

  if (typeof normalizedPrevValue === 'number') {
    return areInputValuesNotEqual(`${normalizedPrevValue}`, normalizedValue);
  }

  if (typeof normalizedPrevValue === 'boolean') {
    return normalizedPrevValue !== normalizedValue;
  }

  if (typeof normalizedValue === 'object') {
    return !!normalizedPrevValue.id
      ? normalizedPrevValue.id !== normalizedValue.id
      : !areArraysEqual(prevValue, value);
  }

  return areInputValuesNotEqual(normalizedPrevValue, normalizedValue);
};
