import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import EmployeeItem from './EmployeeItem';
import { useStyles } from './styles';
import { showAlert } from '../../../../redux/actions';
import {
  deleteEmployee,
  getEmployee,
  postEmployee,
  putEmployee
} from '../../../../services/api/employee';
import {
  EMPLOYEE_DELETE_MESSAGE,
  EMPLOYEE_DELETE_TITLE,
  EMPLOYEE_EDITED,
  EMPLOYEE_DELETED,
  ADMIN_ROLE
} from '../../../../utils/constants/stringConstants';
import ConfirmDeleteModal from '../../../../components/ConfirmDeleteModal';
import UnsavedMessageModal from '../../../../components/UnsavedMessageModal';
import AddEditEmployeeModal from './AddEditEmployeeModal/AddEditEmployeeModal';

export function KeyIndividuals({ commonClasses }) {
  const classes = useStyles();
  const userRole = useSelector((state) => state.session.user.role);
  const dispatchRedux = useDispatch();
  const [employees, setEmployees] = useState([]);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [addEditModalOpen, setAddEditModalOpen] = useState(false);
  const [editEmployee, setEditEmployee] = useState(null);
  const [openModalConfirmUnsaved, setOpenModalConfirmUnsaved] = useState(false);

  const loadEmployees = async () => {
    try {
      const data = await getEmployee();
      setEmployees(data);
    } catch (error) {
      dispatchRedux(
        showAlert({ isShown: true, type: 'error', message: error.response.data.message })
      );
    }
  };

  useEffect(() => {
    loadEmployees();
  }, []);

  const deleteEmployeeHandler = async () => {
    try {
      const data = await deleteEmployee(employeeToDelete.id);
      setEmployees(data);
      dispatchRedux(showAlert({ isShown: true, type: 'success', message: EMPLOYEE_DELETED }));
    } catch (error) {
      dispatchRedux(
        showAlert({ isShown: true, type: 'error', message: error.response.data.message })
      );
    }
    setEmployeeToDelete(null);
  };

  const closeAddEdit = () => {
    setAddEditModalOpen(false);
    setEditEmployee(null);
  };

  const closeUnsavedData = () => {
    setOpenModalConfirmUnsaved(false);
    closeAddEdit();
  };

  const callAddEdit = async (data) => {
    setEditEmployee(data);
    setAddEditModalOpen(true);
  };

  const submit = async (params, id) => {
    try {
      const data = !id ? await postEmployee(params) : await putEmployee(id, params);
      setEmployees(data);
      closeAddEdit();
      dispatchRedux(showAlert({ isShown: true, type: 'success', message: EMPLOYEE_EDITED }));
    } catch (error) {
      dispatchRedux(
        showAlert({ isShown: true, type: 'error', message: error.response.data.message })
      );
    }
  };

  return (
    <div className={classes.wrapper}>
      <UnsavedMessageModal
        open={openModalConfirmUnsaved}
        onClose={closeUnsavedData}
        onCancel={() => setOpenModalConfirmUnsaved(false)}
      />
      <AddEditEmployeeModal
        open={addEditModalOpen}
        data={editEmployee}
        onClose={(isChanged) => (isChanged ? setOpenModalConfirmUnsaved(true) : closeAddEdit())}
        submit={submit}
      />
      <ConfirmDeleteModal
        open={!!employeeToDelete}
        onClose={() => setEmployeeToDelete(null)}
        submit={deleteEmployeeHandler}
        title={EMPLOYEE_DELETE_TITLE}
        text={EMPLOYEE_DELETE_MESSAGE}
      />
      <Grid className={commonClasses.badgeWrapper}>
        <Typography className={commonClasses.badgeHeader}>Who we are</Typography>
        {userRole === ADMIN_ROLE && (
          <Button
            variant="contained"
            className={commonClasses.addButton}
            onClick={() => callAddEdit()}
          >
            Add Employee
          </Button>
        )}
      </Grid>
      <Grid justifyContent="center" container gap="32px">
        {!!employees.length &&
          employees.map((employee) => (
            <EmployeeItem
              employee={employee}
              currentUserRole={userRole}
              deleteEmployee={() => setEmployeeToDelete(employee)}
              editEmployee={callAddEdit}
            />
          ))}
      </Grid>
    </div>
  );
}

KeyIndividuals.propTypes = {
  commonClasses: PropTypes.object
};
