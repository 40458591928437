import React from 'react';
import { styled } from '@mui/material/styles';
import { Tooltip, tooltipClasses } from '@mui/material';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    disablePortal
    TransitionProps={{ timeout: 0 }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F9FAFB',
    borderColor: '#D1D5DB',
    border: '1px',
    borderRadius: '4px',
    color: '#111827',
    boxShadow: theme.shadows[1],
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22.4px',
    maxWidth: 423,
    padding: '6px 16px'
  }
}));

export default CustomTooltip;
