import React, { Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import AuthGuard from 'src/components/AuthGuard';
import { showAlert } from 'src/redux/actions';
import { Header } from 'src/components/Header/Header';
import { defaultLocalStorageUserSettings } from 'src/utils/userLocalStorageSettings';
import Page from 'src/components/Page';
import { slotProps } from 'src/components/Login/slotProps';
import useStyles from './styles';
import { getTitle } from './pathesHandler';

function MainLayout() {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.session.alert);

  const classes = useStyles();
  const location = useLocation();
  const isNotPdf = () => location?.pathname?.indexOf('document-pdf') === -1;

  const closeAlertMessage = () => {
    dispatch(showAlert({ isShown: false, type: '', message: '' }));
  };

  useEffect(() => {
    const localStorageUserSettings = JSON.parse(localStorage.getItem('userSettings'));
    if (!localStorageUserSettings) {
      localStorage.setItem('userSettings', JSON.stringify(defaultLocalStorageUserSettings));
    }
  });

  const dynamicClassName = () => {
    if (!isNotPdf()) return '';
    const pathClasses = {
      '/home': classes.home,
      '/about-us': classes.aboutUs
    };
    if (location.pathname.startsWith('/errors/')) {
      return classes.errors;
    }
    return pathClasses[location.pathname] || classes.content;
  };

  return (
    <Page title={getTitle(location.pathname)}>
      <AuthGuard>
        {alert.isShown && (
          <Snackbar
            ClickAwayListenerProps={{ onClickAway: () => null }}
            className={classes.alertInfo}
            open={alert.isShown}
            autoHideDuration={4000}
            onClose={closeAlertMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={closeAlertMessage}
              sx={slotProps}
              severity={alert.type}
              variant="filled"
            >
              {alert.message}
            </Alert>
          </Snackbar>
        )}
        <div className={dynamicClassName()}>
          {isNotPdf() && <Header />}
          <Suspense fallback={<LinearProgress />}>
            <Outlet />
          </Suspense>
        </div>
      </AuthGuard>
    </Page>
  );
}

export default MainLayout;
